import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./backimg.css";

import AuthApi from '../services/authApi';
import logo from './../images/mpayicon2.png';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
toast.configure()

function OtpPage() {
    const [storeotp, otpText] = useState('');
    const history = useHistory();

    const location = useLocation();

    const handleOtp = e => {

        otpText(e.target.value);
    };
   
    
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 


    const authenticateOtp = async () => {
        if ( localStorage.getItem('otp') === storeotp) {
            toast.success('Successfully Logged in to Mpay',
            {position: toast.POSITION.BOTTOM_CENTER})
            history.push('/dashboard');
            localStorage.setItem('isLoggedIn', true);
        }
        else {
            alert('Oops, Enter valid OTP')
        }
    }

    return (
        <div class="outer">
            <div class="inner">
                <div class="body">
                    <form>
                        <center>
                            <img src={logo} alt="logo" width="100" height="50" />
                        </center>
                        <span class="larger">Mobi<span class="spcolor">Online</span> Payment</span>
                        <hr></hr>

                        <div className="form-group">
                            <label className="medium">Verify OTP :</label>
                            <h6 className="txt">Step 1 : Enter 4 digit OTP which sent on your phone number and click on verify button.</h6>

                            <h6 className="txt">Step 2: Incase of failure/error - Refresh the page and follow from step 1.</h6>
                    &nbsp;
                    <input style={{ borderRadius: '15px' }} type="tel" id="storeotp" onChange={handleOtp} className="form-control" pattern="[0-9]{4}" maxlength="4" placeholder="Enter 4 Digit OTP" required/>
                        </div>
                &nbsp;


                            <button style={{ backgroundColor: '#ff8c00' }}
                            type="button" className="btn btn-lg btn-block" onClick={() => authenticateOtp()}>Verify OTP</button>
                 &nbsp;
                {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}

                    </form>
                    {/* <center><p>Made with <img src={logo1} alt="logo" width="20" height="20" /> in India!</p></center> */}
                </div>
            </div>
        </div>
    );
}

export default OtpPage;