import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import PaymentIcon from '@material-ui/icons/AccountBalance';
import TranHisIcon from '@material-ui/icons/AccountBalanceWallet';
// import AddComplaintIcon from '@material-ui/icons/AddComment';
import AllComplaintIcon from '@material-ui/icons/Forum';
import logo from './../images/mpayicon2.png';
import Logout from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';

import ReportIcon from '@material-ui/icons/Report';
// import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import TotalSTB from './totalSTB';
import RecentTransaction from './recentTransaction';
import logo1 from './../images/heart1.png';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <h5>Made with <img src={logo1} alt="logo" width="25" height="25" /> in India!</h5>
        </Typography>
    );
}

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: '#F5F5F5'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 220,
    },
}));

export default function Dashboard() {
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    console.log(isLoggedIn);
    if (isLoggedIn === null) {
        history.push('/login');
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const one = () => {
        history.push("/dashboard")
    };
    const two = () => {
        history.push("/allComplaint")
    };
    const three = () => {
        history.push("/paymentHistory")
    };
    const four = () => {
        history.push("/helpnsupport")
    };
    const five = () => {
        history.push("/termsncondition")
    };
    const six = () => {
        history.push("/login")
        localStorage.clear();
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar style={{ backgroundColor: '#ff8c00' }} position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Dashboard
          </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={0} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List> <ListSubheader inset><img src={logo} alt="logo" width="110" height="45" /></ListSubheader><hr></hr>

                    <span>
                        <ListItem button onClick={() => one()} >
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem></span>
                    {/* <span> <Link style={{color:'#3E3E3E'}} href='/subDetail' onClick={"/subDetail"}> 
          <ListItem button >
                <ListItemIcon>
                    <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="Payment" />
            </ListItem></Link></span>
            <span> <Link style={{color:'#3E3E3E'}} href='/subDetail' onClick={"/subDetail"}> 
            <ListItem button >
                <ListItemIcon>
                    <AddComplaintIcon />
                </ListItemIcon>
                <ListItemText primary="Complaint" />
            </ListItem></Link></span> */}
                    <span>
                        <ListItem button onClick={() => two()}>
                            <ListItemIcon>
                                <AllComplaintIcon />
                            </ListItemIcon>
                            <ListItemText primary="View & Add Complaint" />
                        </ListItem></span>
                    <span>
                        <ListItem button onClick={() => three()} >
                            <ListItemIcon>
                                <TranHisIcon />
                            </ListItemIcon>
                            <ListItemText primary="Transaction History" />
                        </ListItem></span></List>
                <Divider />
                <List>
                    <ListItem button onClick={() => four()}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help & Support" />
                    </ListItem>
                    <ListItem button onClick={() => five()}>
                        <ListItemIcon>
                            <ReportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Terms & Condition" />
                    </ListItem>
                    <ListItem button onClick={() => six()}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={9} lg={9}>
                            <Paper className={classes.paper}>
                                <TotalSTB />
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={3} lg={3}>
                            <Paper className={classes.paper}>
                                <RecentTransaction />
                            </Paper>
                        </Grid>

                        {/* <Grid item xs={12} md={8} lg={8}>
                            <Paper  className={fixedHeightPaper}>
                                <ViewSTB />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paper}>
                                <RecentTran />
                            </Paper>
                        </Grid> */}

                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}