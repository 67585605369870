import React, { useState, useEffect, useCallback } from "react";
import "./STBpayment.css";
import tvlogo from "./../images/tvicon.png";
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi from "../services/authApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";

import Loader from "react-loader-spinner";
import swal from "sweetalert";
function Subcription() {
  const history = useHistory();

  var [isButtonLoading, setButtonLoading] = useState(false);
  let getCompanyName = localStorage.getItem("companyName");
  let getCustomerId = localStorage.getItem("customerID");
  let getCustomerName = localStorage.getItem("customerName");
  let amount = localStorage.getItem("dueAmount");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const generateTraknpayOrderaid = async () => {
  //   let orderIdTraknpay = {
  //     address_line_0: localStorage.getItem("traknpaySaltKey"),
  //     //"701322e5f47a56440ad7c81aebd15c02df6b7c9b",
  //     address_line_1: "AD1",
  //     address_line_2: "AD2",
  //     amount: amount,
  //     api_key: localStorage.getItem("traknpayApiKey"),
  //     //"73384d12-2c1f-415f-ad0c-248211594574",
  //     city: "Banglore",
  //     country: "IND",
  //     currency: "INR",
  //     description: "upi payment",
  //     email: "kifijow167@maillei.com",
  //     interface_type: "android_sdk",
  //     mode: "LIVE",
  //     name: localStorage.getItem("customerName"),
  //     order_id: tranRef,
  //     phone: localStorage.getItem("phoneNumber"),
  //     return_url:
  //       "http://mobicabledev-env.eba-2w8s3byw.ap-south-1.elasticbeanstalk.com/api/response_page.php",
  //     state: "Karnataka",
  //     udf1: localStorage.getItem("customerNumber"),
  //     udf2: localStorage.getItem("ownerId"),
  //     udf3: localStorage.getItem("sourceDb"),
  //     udf4: userId,
  //     udf5: localStorage.getItem("customerId"),
  //     zip_code: "560001",
  //   };

  //   await AuthApi.getTraknpayOrderId(orderIdTraknpay).then((response) => {
  //     console.log(response);
  //     if (response) {
  //       localStorage.setItem("traknPayOrderId", response.data);
  //       console.log("hiiiiiiiiiiii");
  //       console.log(localStorage.getItem("traknPayOrderId"));
  //       handlePaymentTraknpay();
  //     }
  //   });
  // };

  // const generateTraknpay = async () => {
  //   let sendToTraknpay = {
  //     address_line_1: localStorage.getItem("traknpaySaltKey"),
  //     amount: amount,
  //     api_key: localStorage.getItem("traknpayApiKey"),
  //     city: "Bangalore",
  //     country: "India",
  //     currency: "INR",
  //     description: "online payment",
  //     email: "kifijow167@maillei.com",
  //     name: localStorage.getItem("customerName"),
  //     order_id: tranRef,
  //     phone: localStorage.getItem("phoneNumber"),
  //     return_url:
  //       "https://www.mobicollector.com/traknpay/response_pageReact.php",
  //     return_url_failure:
  //       "https://www.mobicollector.com/traknpay/response_pageReact.php",
  //     state: "Karnataka",
  //     udf1: localStorage.getItem("customerNumber"),
  //     udf2: localStorage.getItem("ownerId"),
  //     udf3: localStorage.getItem("sourceDb"),
  //     udf4: userId,
  //     udf5: localStorage.getItem("customerId"),
  //     zip_code: "560001",
  //     // hash: localStorage.getItem("traknPayOrderId").toString,
  //   };

  //   await AuthApi.getTraknpayUrlRequest(sendToTraknpay).then((response) => {
  //     console.log(response);
  //     if (response.data.data != null) {
  //       traknpayUrl = response.data.data.url;
  //       console.log(traknpayUrl);
  //       initTransactionTraknpay();
  //       window.open(traknpayUrl, "_self");
  //     }
  //   });
  // };
  // const handlePayment = useCallback(
  //   (id, amount, name) => {
  //     //const order = await createOrder(params);

  //     const options: RazorpayOptions = {
  //       key: localStorage.getItem("razorPay_id"),
  //       amount: amount,
  //       currency: "INR",
  //       name: localStorage.getItem("ownerCompanyName"),
  //       description: "Test Transaction",
  //       image: "https://example.com/your_logo",
  //       order_id: localStorage.getItem("razorPayOrderId"),
  //       handler: (res) => {
  //         successTransaction();
  //         console.log(res);
  //       },
  //       prefill: {
  //         name: localStorage.getItem("customerName"),
  //         email: "youremail@example.com",
  //         contact: localStorage.getItem("phoneNumber"),
  //       },
  //       notes: {
  //         address: "Razorpay Corporate Office",
  //       },
  //       theme: {
  //         color: "#3399cc",
  //       },
  //     };

  //     const rzpay = new Razorpay(options);
  //     rzpay.open();
  //   },
  //   [Razorpay]
  // );

  // const initTransactionTraknpay = async () => {
  //   let sendData = {
  //     cust_id: localStorage.getItem("customerNumber"),
  //     owner_id: localStorage.getItem("ownerId"),
  //     amount: amount,
  //     tran_id: tranRef,
  //     //"merchant_id":"",
  //     pay_partner: "traknpay",
  //     pay_req: "{}",
  //     pay_stat1: "initiated",
  //     comments: "Web",
  //     flag: "I",
  //   };

  //   await AuthApi.sendTransactionDataToDb(sendData).then((response) => {
  //     console.log(response);
  //   });
  // };

  // const initTransactionRazorpay = async () => {
  //   let sendData = {
  //     cust_id: localStorage.getItem("customerNumber"),
  //     owner_id: localStorage.getItem("ownerId"),
  //     amount: amount,
  //     tran_id: localStorage.getItem("razorPayOrderId"),
  //     //"merchant_id":"",
  //     pay_partner: "razorpay",
  //     pay_req: "{}",
  //     pay_stat1: "initiated",
  //     comments: "Web",
  //     flag: "I",
  //   };

  //   await AuthApi.sendTransactionDataToDb(sendData).then((response) => {
  //     console.log(response);
  //   });
  // };

  // const successTransaction = async () => {
  //   let sendData = {
  //     cust_id: localStorage.getItem("customerNumber"),
  //     owner_id: localStorage.getItem("ownerId"),
  //     amount: amount,
  //     tran_id: localStorage.getItem("razorPayOrderId"),
  //     //"merchant_id":"",
  //     pay_partner: "razorpay",
  //     pay_req: "{}",
  //     pay_stat1: "success",
  //     comments: "Web",
  //     flag: "S",
  //   };

  //   await AuthApi.sendTransactionDataToDb(sendData).then((response) => {
  //     console.log(response);
  //   });
  // };

  // const generaterazorpay = async () => {
  //   let sendToRazorpay = {
  //     amount: amount,
  //     receipt: "MPay_Payment",
  //     operator_id: localStorage.getItem("ownerId"),
  //     cust_num: localStorage.getItem("customerNumber"),
  //     username: localStorage.getItem("razorPay_id"),
  //     password: localStorage.getItem("razorPay_secret"),
  //   };

  //   await AuthApi.getRazorpayOrderid(sendToRazorpay).then((response) => {
  //     console.log(response);
  //     if (response) {
  //       localStorage.setItem("razorPayOrderId", response.data.id);
  //       initTransactionRazorpay();
  //       handlePayment();
  //     }
  //   });
  // };

  const onSignIn = async () => {
    //  alert(localStorage.getItem("ownerId"));
    setButtonLoading(true);
    if (amount < 1) {
      setButtonLoading(false);
      alert("Amount should be greater than (\u20B9) 1.00");
    } else {
      setButtonLoading(true);
      let newDate = new Date();
      let getYear = newDate.getFullYear().toString();
      let getMonth = newDate.getMonth().toString();
      let getDate = newDate.getDate().toString();
      let getMSec = newDate.getMilliseconds().toString();
      let combineDate = getYear + getMonth + getDate + getMSec;

      let tranRef =
        "RSPT" + localStorage.getItem("customerNumber") + combineDate;
      console.log("tranRef njkskjf bsdfbkb sbfsdf");
      console.log(tranRef);
      // let tran =
      //   "TRNNXTSUBWEB" + parseInt(localStorage.getItem("ownerId")) + Date.now();
      let data = JSON.stringify({
        AMOUNT: amount,
        PHONE: localStorage.getItem("phoneNumber"),
        OPERATOR_ID: localStorage.getItem("ownerID"),
        flag: "A",
        CUST_NUM: localStorage.getItem("customerNumber"),
        customerName: localStorage.getItem("customerName"),
        tran_id: tranRef,
      });
      console.log(data);
      await AuthApi.cashfree_init(data)
        .then((response) => {
          console.log("test");
          console.log(response);
          if (response.data.p_out_mssg_flg === "S") {
            window.location.href =
              "https://apps.mobiezy.in/CASHFREE/REACT_MPAY/cashfree_ui.php?paymentSessionId=" +
              response.data.payment_session_id;
          } else {
            setButtonLoading(false);

            swal("Error", "response.data.p_out_mssg", "warning");
          }
        })
        .catch((e) => {
          setButtonLoading(false);
          console.log(e);
        });
      // window.location.href = "https://mobicollector.com/cashfree/checkout.php?PHONE=9989839318&amount=1&name=Test&op_id=8488&tran_id=TRNNXTSUB84881651049226249"
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-start" alignItems="flex-start">
          <IconButton
            style={{ paddingRight: "30px" }}
            color="inherit"
            onClick={() => history.push("/dashboard")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton
            deleteIcon3
            size="large"
            style={{ padding: "3px", paddingRight: "15px", color: "#ff8c00" }}
          >
            <Help HelpIcon />
          </IconButton>
        </Grid>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} width="140" height="140" />
        <div style={{ paddingTop: "25px", paddingLeft: "45px" }}>
          <h4>{getCompanyName}</h4>
          <h5>ID : {getCustomerId}</h5>
          <h5>Name : {getCustomerName}</h5>
        </div>
      </div>
      <h4 style={{ color: "#ff8c00", paddingLeft: "5px", paddingTop: "5px" }}>
        <hr></hr>Billing Details({"\u20B9"}) <hr></hr>
      </h4>
      <div id="textbox">
        <h5 class="alignleft">Status</h5>
        <h5 class="alignright" style={{ color: "green" }}>
          Active
        </h5>
      </div>

      {/* <div id="textbox"><hr></hr>
  <h5 class="alignleft">Month</h5>
  <h5 class="alignright">Feb-2021</h5>
  
</div> */}

      <div id="textbox">
        <hr></hr>
        <h5 class="alignleft">Amount Due</h5>
        <h5 class="alignright" style={{ color: "red" }}>
          {"\u20B9"} {amount} /-
        </h5>
      </div>

      <center>
        <hr></hr> &nbsp;{" "}
        <h4>
          Total Payable Amount : {"\u20B9"} {amount} /-
        </h4>
        {isButtonLoading === true ? (
          <center>
            <Loader type="TailSpin" color=" #ff8c00" height="70" width="70" />
          </center>
        ) : (
          <button
            style={{
              color: "white",
              backgroundColor: "#ff8c00",
              width: "200px",
              textDecoration: "none",
            }}
            type="submit"
            className="btn btn-lg btn-block"
            onClick={
              () => onSignIn()
              //(window.location.href = "/cashfree")
              // localStorage.getItem("razorpayFlag") === "Y"
              //   ? generaterazorpay()
              //   : generateTraknpay()
            }
          >
            Proceed to Pay{" "}
          </button>
        )}
        &nbsp;{" "}
      </center>
    </React.Fragment>
  );
}

export default Subcription;
