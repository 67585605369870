import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Complaint from "./../images/notification.png";
import Rupay from "./../images/rupees1.png";
import Avatar from "@material-ui/core/Avatar";
import { Container, Button } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Link from "@material-ui/core/Link";
import Loader from "react-loader-spinner";
import moment from "moment";
import AuthApi from "../services/authApi";
import AuthApi1 from "../services/authApi";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    background: "white",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#ff7700",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [comp, setComp] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = async () => {
    try {
      let userDatas = {
        cust_id: localStorage.getItem("customerNumber"),
      };
      console.log(userDatas);
      await AuthApi1.getTranHistoryCashfree(userDatas).then((response) => {
        console.log(response);
        if (response.data.customerPaymentHistory !== undefined) {
          console.log(" successful1");
          setLoading(true);
          let planss = response.data.customerPaymentHistory;

          let planslists = planss.map((plan) => {
            return {
              TRAN_ID: plan.TRAN_ID,
              // user_name:plan.user_name,
              COLLECTION_DATE: plan.COLLECTION_DATE,
              collected_amount: plan.collected_amount,
              SUCCESS: plan.SUCCESS,
            };
          });

          setComp(planslists);
        } else {
          alert("There is No Transactions");
        }
      });
    } catch (error) {}
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const na = "---";

  const paymentlistItems = comp.map((plan, panel) => (
    <Accordion
      expanded={expanded === panel + 1}
      onChange={handleChange(panel + 1)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel+1-content"
        id="panel+1-header"
      >
        <Avatar alt="Tv icon" src={Rupay} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Typography style={{ paddingTop: "8px" }} className={classes.heading}>
          {moment(plan.COLLECTION_DATE).format("YYYY/MM/DD kk:mm:ss")}
        </Typography>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Typography
          style={{ paddingTop: "8px", color: "green" }}
          className={classes.secondaryHeading}
        >
          {"\u20B9"} {plan.collected_amount} /-
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Transaction ID : {plan.TRAN_ID === "" ? na : plan.TRAN_ID}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  const history = useHistory();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  console.log(isLoggedIn);
  if (isLoggedIn === null) {
    history.push("/login");
  }

  return (
    <div className={classes.root}>
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => history.push("/dashboard")}
        >
          <ArrowBackIcon />
        </IconButton>
        Transaction History
      </h3>
      {/* <div>
     <Link href="/addComplaint" onClick={'/addComplaint'}>
        <Fab  aria-label="add" className={classes.margin}>
        
          <AddIcon />
        </Fab></Link>
      </div> */}
      {!loading ? (
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "200px",
          }}
        >
          <Loader type="Oval" color=" #ff8c00" height="70" width="70" />
        </div>
      ) : (
        paymentlistItems
      )}

      {/* <Container>
        <Link href="/addComplaint">
          <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

            <AddIcon />
          </Fab></Link>
      </Container> */}
    </div>
  );
}
