import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./login&otp/login";
import OtpPage from "./login&otp/otpPage";
import Dashboard from "./dashboard/Dashboard";
import PaymentHis from "./payment/paymentHistory";
import Notifications from "./notification/allNotificaton";
import AddComplaint from "./subcriptionDetail/addComplaint";
import SubDetail from "./subcriptionDetail/subDetail";
import AllComplaint from "./subcriptionDetail/allComplaint";
import Termsncondition from "./termsncondition";
import Helpnsupport from "./helpnsupport";
import Razorpay from "./subcriptionDetail/Razorpay";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/otpPage" component={OtpPage} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/paymentHistory" component={PaymentHis} />
        <Route path="/allNotificaton" component={Notifications} />
        <Route path="/addComplaint" component={AddComplaint} />
        <Route path="/subDetail" component={SubDetail} />
        <Route path="/allComplaint" component={AllComplaint} />
        <Route path="/termsncondition" component={Termsncondition} />
        <Route path="/helpnsupport" component={Helpnsupport} />
        <Route path="/razor" component={Razorpay} />
      </Switch>
    </Router>
  );
}

export default App;
