import React, { Component } from "react";
import tvlogo from './../images/adds.jpg';



class Subcription extends Component {

  render() {
    return (
       
     
            <img alt="Tv icon" src={tvlogo} width="250" height="360" />
      
    );
  }
}

export default Subcription;