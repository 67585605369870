import React, { useState } from "react";
import "./backimg.css";
import AuthApi1 from "../services/authApi";
import logo from "./../images/mpayicon2.png";
import logo1 from "./../images/heart1.png";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();


  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const signIn = async () => {
    // eslint-disable-next-line no-unused-vars
    let isValid = true;
    if (phoneNumber === "") {
      setErrors({
        phoneNumber: <font color="red">Phone number can't be empty.</font>,
      });
      isValid = false;
      return;
    }
    let userData = {

          PHONE: phoneNumber,
    };
    await AuthApi1.newlogin(userData)
      .then((response) => {
        console.log(response);
        if (response.data.p_out_mssg_flg === "E") {
          alert(
            "Your number is not registered. Please check with your cable operator."
          );
        } else {
         
          console.log("jsdjhs gdfjs jgdjfj");
          localStorage.setItem("phoneNumber", phoneNumber);
          console.log(localStorage.getItem("phoneNumber"))
          console.log(response.data.otpCode);
          localStorage.setItem('otp', response.data.otpCode);
          history.push('/otpPage');
            toast.error("Sending OTP Please Wait...", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
        
      }}
      )
      .catch((e) => {
        // history.push('/unauthorized');
        alert("Internet issue");
        console.log(e);
      });
  };

  return (
    <div class="outer">
      <div class="inner">
        <div class="body">
          <form>
            <center>
              {" "}
              <img src={logo} alt="logo" width="100" height="50" />
            </center>
            <span class="larger">
              Mobi<span class="spcolor">Online</span> Payment
            </span>
            <hr />
            &nbsp;
            <div className="form-group">
              <label className="medium">Enter Phone Number :</label>
              <input
                style={{ borderRadius: "15px" }}
                type="tel"
                id="phoneNumber"
                onChange={handlePhoneNumber}
                className="form-control"
                pattern="[1-9]{1}[0-9]{9}"
                maxlength="10"
                placeholder="Phone Number"
                required
              />
              <center>
                {" "}
                <label style={{ color: "green", fontSize: "11px" }}>
                  (Note: OTP will be sent to this phone number)
                </label>
              </center>
              <div classname="text-danger">{errors.phoneNumber}</div>
            </div>
            &nbsp;
            <button
              style={{ backgroundColor: "#ff8c00" }}
              type="button"
              className="btn btn-lg btn-block"
              onClick={() => signIn()}
            >
              Send OTP
            </button>
            &nbsp;
            {/* <p className="forgot-password text-right">
          Forgot <a href="#">password?</a>
        </p> */}
          </form>
          <center>
            <p>
              Made with <img src={logo1} alt="logo" width="20" height="20" /> in
              India!
            </p>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Login;
