/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import tvlogo from "./../images/tvicon.png";
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi1 from "../services/authApi";
import { useHistory } from "react-router-dom";

function Subcription() {
  const [customerId, setCustomerId] = useState("-");
  const [ownerCompanyName, setCompanyName] = useState("-");
  const [customerName, setCustomerName] = useState("-");
  
  const history = useHistory();
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    let userData = { PHONE : localStorage.getItem("phoneNumber")
    };
    await AuthApi1.custDetails(userData)
      .then((response) => {
        console.log(response);
        console.log("kydhdbddhdhf yfhhbhbf hgfh hbdfh");
        let data = response.data.customerInfo;
        
        // setCustomerNumber(data.customer_number);
        setCustomerId(data.customer_id);
        // setOwnerId(data.owner_id);
        setCompanyName(data.owner_company_name);
        // setOwnerName(data.owner_name);
        // setOwnerPhone(data.owner_phone);
        setCustomerName(data.customer_name);
        // setMSO(data.mso);
        // setStbid(data.stb_id);
        // setVCno(data.vc_num);
        // setDue(data.due_amount);

        
        localStorage.setItem("customerNumber", data.customer_number);
        localStorage.setItem("customerID", data.customer_id);
        localStorage.setItem("ownerID", data.owner_id);
        localStorage.setItem("phoneNum", data.phone);
        localStorage.setItem("companyName", data.owner_company_name);
        localStorage.setItem("ownerName", data.owner_name);
        localStorage.setItem("ownerPhone", data.owner_phone);
        localStorage.setItem("customerName", data.customer_name);
        localStorage.setItem("msoID", data.mso);
        localStorage.setItem("stbID", data.stb_id);
        localStorage.setItem("vcNumber", data.vc_num);
        localStorage.setItem("dueAmount", data.due_amount);
       
      })
      .catch((e) => {
        alert("error in getting details");
        console.log(e);
      });
  };

  const getnextpage = () => {
    history.push("/subDetail");
  };
  return (
    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton
            deleteIcon3
            size="large"
            style={{ padding: "3px", paddingRight: "15px", color: "#ff8c00" }}
          >
            <Help HelpIcon />
          </IconButton>
        </Grid>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} wwidth="140" height="140" />
        <div style={{ paddingTop: "25px", paddingLeft: "45px" }}>
          <h4>{customerName}</h4>
          <h5>{customerId}</h5>
          <h5>{ownerCompanyName}</h5>
        </div>
      </div>
      <div>
        {/* <hr></hr>
        <center>  <h4 style={{ color: "#ff8c00", paddingLeft: '5px', paddingTop: '5px' }}>Your Subcription</h4></center> */}
      </div>
      <div>
        <hr></hr>
        &nbsp;
        <center>
          <h4 style={{ color: "#456383" }}>
            Click on the Pay Now to view the Bill & Detail.
          </h4>
        </center>{" "}
      </div>
      &nbsp;
      <center>
        <button
          style={{ color: "white", backgroundColor: "#ff8c00", width: "200px" }}
          onClick={() => getnextpage()}
          type="button"
          className="btn btn-lg btn-block"
        >
          Pay Now
        </button>
      </center>
      &nbsp;
      <p></p>
    </React.Fragment>
  );
}

export default Subcription;
