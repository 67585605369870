import axios from "axios";

let authApi = axios.create({
  baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "aMqwyQJdA1aqH5GkpG5NR78UyswcHhkEaMZCfrC8",
    "Content-Type": "application/json",
  },
});

let authApi1 = axios.create({
  // baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "ffWim00xLx52kC5AI8xJk5B1tSBrRnyH3TqOv8Y5",
    "Content-Type": "application/json",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {



  getComplaints(value) {
    return authApi.post("/prod/mpay-getcustomercomplaints", value);
  },

  addComplaintsrupee(value) {
    return authApi.post("/prod/mobiezyb2c_rupayee_manageComplaintsNew", value);
  },
  addComplaints(value) {
    return authApi.post("/prod/manageComplaint", value);
  },


//-----------------------------------------

newlogin(value){
  return authApi1.post(
    "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mobiezyb2c_v3_getphonenumberslist",
    value
  );
},

custDetails(value){
  return authApi1.post(
    "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mpayreact_v3_getcustinfodetails_prc",
    value
  );
},


cashfree_init(value) {
  return authApi.post(
    "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/generate_token_link_payment",
    value
  );
},

  getTranHistoryCashfree(value) {
    return authApi1.post(
      "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mobiezy_v3_getcustomerpaymenthistory",
      value
    );
  },
};
